// Function to get the current template name
function getCurrentTemplate() {
  return document.body.dataset.template;
}

// Function to load scripts based on template
async function loadTemplateScripts() {
  const template = getCurrentTemplate();

  // Load loyalty script for logged-in customers
  if (window.customerId) {
    await import('./loyalty');
  }

  // Template-specific script loading
  switch (template) {

    // Collections
    case 'collection':
    case 'search':
    case 'collection.filter-banner':
      await import('./collection');
      break;
    case 'collection.daily-lace':
    case 'collection.atelier':
      await import('./daily-lace');
      await import('./collection');
      break;
    case 'collection.prabal-gurung':
      await import('./prabal-gurung');
      await import('./daily-lace');
      await import('./collection');
      break;
    case 'collection.body':
      await import('./colorverse');
      await import('./collection');
      break;
    case 'collection.manifestation':
      await import('./daily-lace');
      await import('./manifestation-collection');
      break;
    case 'list-collections':
    case 'collection.gift-guide':
    case 'collection.editorial':
      await import('./list-collections');
      break;
    case 'collection.bundle':
    case 'collection.Bouquet-Bundle':
      await import('./bundle');
      break;

    // Products
    case 'product':
    case 'default_product_v2':
    case 'product.bras':
      await import('./product');
      break;
    case 'product.fix':
      await import('./product_new');
      break;

    // Home
    case 'index':
      await import('./home');
      break;

    // Account
    case 'customers/addresses':
      await import('./account');
      await import('./addresses');
      break;
    case 'customers/register':
      await import('./account');
      await import('./register');
      break;
  }

  // Pages
  if (template.startsWith('page')) {
    await import('./page');
    if (template === 'page.thong' || template === 'page.decades') {
      await import('./thong');
    }
    if (template === 'page.guarantee') {
      await import('./guarantee');
    }
    if (template === 'page.lc-signup') {
      await import('./lc-signup');
    }
    if (template === 'page.colorverse') {
      await import('./colorverse');
      await import('./collection');
    }
  }

  // Handle blog template
  if (template === 'blog') {
    await import('./blog');
  }
}

// Load scripts when DOM is ready
document.addEventListener('DOMContentLoaded', loadTemplateScripts);
